#error {
  position: relative;
  height: 100vh;
}

#error .error {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error {
  max-width: 710px;
  width: 100%;
  padding-left: 190px;
  line-height: 1.4;
}

.error .error-404 {
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
  height: 150px;
}

.error .error-404 h1 {
  width: max-content;
  font-family: "Passion One", cursive;
  color: #2992D0;
  font-size: 150px;
  letter-spacing: 15.5px;
  margin: 0px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.error h2 {
  font-family: "Raleway", sans-serif;
  color: #292929;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin-top: 0;
}

.error p {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
}

.error a {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #fff;
  display: inline-block;
  background-color: #2992D0;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
  -webkit-box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.error a:hover {
  color: #fff;
  background-color: #2993d0c9;
}

@media only screen and (max-width: 480px) {
  .error {
    text-align: center;
  }
  .error .error-404 {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
  }
  .error {
    padding-left: 15px;
    padding-right: 15px;
  }
}
