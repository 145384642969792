.btn-delete:hover{
  background-color: #f07b74;
  border-color: #f07b74;
}
.btn-cancel:hover{
  background-color: #ccccccd4;
}
.btn-cancel{
  background-color: #ccc;
}
.sweetyesbutton{
    background-color: #5E4BCE;
    color: white;
    width: 30%;
}

.sweetyesbutton:hover{
    background-color: rgba(95, 75, 206, 0.877) !important;
    color: white;
}

.sweetcancelbutton{
    width: 30%;
}
.sweetcancelbutton:hover{
    background-color: rgb(238,238,238);
}

/* width */
.customScroll::-webkit-scrollbar {
    height: 5px;
  }
  
  /* Track */
  .customScroll::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .customScroll::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  .customScroll::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .form-group{
    -webkit-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .sticky-footer{
    float: right;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #e8e8e8;
    color: white;
    text-align: right;
    padding: .5rem;
  }

  .roles-select-error{
    border: solid 1px #dc3545;
  }

.ReactFlagsSelect-module_flagsSelect__2pfa2{
  padding-bottom: 0;
}