/*
Remove up and down arrows from number input
*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



.form-control:hover{
    border-color: rgba(0, 0, 0, 0.87) !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-radius: 4px !important;
}
.form-control:focus{
    border-color: #2992d066 !important;
    border-style: solid !important;
    border-width: 2px !important;
    box-shadow: none;
}
.form-control is-invalid:focus{
  box-shadow: none;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #2992d066 !important;
    border-style: solid !important;
    border-width: 2px !important;
    box-shadow: none;
}

.rdrDefinedRangesWrapper{
  display: none !important;
}
.dashboarddropdown{
  width: 15rem !important;
  margin-top: 27px;
}