// .select-dropdown,
// .select-dropdown * {
//   margin: 0;
//   padding: 0;
//   position: relative;
//   box-sizing: border-box;
// }
// .select-dropdown {
//   position: relative;
//   background-color: #335675;
//   border: none;
//   border-radius: 4px;
//   margin: 10px 10px;
//   box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2);
// }

// // // .select-dropdown option:hover {
// // // }

// .select-dropdown select {
//   font-size: 1rem;
//   font-weight: normal;
//   width: 100%;
//   padding: 8px 24px 8px 10px;
//   background-color: #335675;
//   border: none;
//   color: white;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   border-radius: 0.3rem;
//   appearance: none;
//   box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2);

//   > option {
//     width: 100%;
//     color: #91a0af;
//     transition: 0.3s;
//   }
//   option:hover {
//     background-color: #1d3449;
//     color: green;
//   }
// }
// .select-dropdown select:active,
// .select-dropdown select:focus {
//   outline: green;
//   outline-color: green;
//   // box-shadow: none;
// }
// .select-dropdown:after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   right: 8px;
//   width: 0;
//   height: 0;
//   margin-top: -2px;
//   border-top: 5px solid #aaa;
//   border-right: 5px solid transparent;
//   border-left: 5px solid transparent;
//   // border-color: red;
// }

.box select {
  // background-color: #0f357e;
  background-color: #21223361;
  color: white;
  // width: 250px;
  width: 95%;
  margin-left: auto;
  border: none;
  font-size: 15px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 6px;
  // margin-bottom: 5px;
  // margin-right: -2px;
  padding: 15px;
}

.box::before {
  content: "\f13a";
  font-family: FontAwesome;
  position: absolute;
  top: 9px;
  right: 0;
  width: 25%;
  height: 100%;
  text-align: center;
  font-size: 25px;
  line-height: 45px;
  color: white;
  // background-color: rgba(255, 255, 255, 0.1);
  pointer-events: none;
}

.box:hover::before {
  // color: rgba(255, 255, 255, 0.6);
  // background-color: rgba(255, 255, 255, 0.2);
}

.box select option {
  padding: 30px;
  background-color: #2992d0 !important;
  cursor: pointer;
}

.box select option:hover {
  background-color: #ffffff33 !important
}
